<script lang="ts">
  import DatePicker from '$lib/components/DatePicker.svelte';
  import { errorProvider } from '$lib/error-handling/errorProvider';
  import type { LicenseDetails } from '$lib/root/LicenseQuotingUtils';
  import dayjs from 'dayjs/esm/index.js';
  import type {
    GroupLicensePrice,
    LicenseRouterPricingResponse,
  } from 'shared/definitions/license-definitions';
  import { calculateGroupLicensePrice } from 'shared/src/licenses/calculate-group-license-price';
  import { dateString } from 'shared/src/utils/date.utils';
  import { createEventDispatcher } from 'svelte';
  import { twMerge } from 'tailwind-merge';

  import { loadGroupLicensePrices } from './loadLicensePrices';

  export let students: number = 5;
  export let price: GroupLicensePrice | null = null;
  export let minStudents: number = 5;
  export let months: 6 | 12 = 12;
  export let starts: Date | null = null;
  export let showDates: boolean = false;
  export let isValid: boolean = true;
  export let highlightClass = '';
  /** Use tight margins, as when used in flex column */
  export let tight = false;

  let clazz = '';
  export { clazz as class };

  let dateIsValid = true;

  $: ends = dayjs(starts).add(months, 'months').toDate();

  let hasSixMonthOption = false;

  let error: string = '';
  $: dateError = dateIsValid ? '' : 'Start date is not valid.';

  let routerPricingResponse: LicenseRouterPricingResponse;

  const dispatch = createEventDispatcher<{ ready: null }>();

  loadGroupLicensePrices()
    .then((response: LicenseRouterPricingResponse) => {
      routerPricingResponse = response;
      setStudents();
      dispatch('ready');
    })
    .catch((err) => {
      error = `Sorry, there was an error loading pricing. Please refresh the page and if the problem persists,
      contact us at support@utheory.com.
      `;
      errorProvider.error(err);
      console.error(err);
    });

  export function setDuration(newDuration: 6 | 12) {
    months = newDuration;
    setStudents();
  }

  $: {
    isValid = !error && dateIsValid;
  }

  export function setStudents(nStudents: number = students) {
    students = nStudents;
    price = null;
    if (routerPricingResponse) {
      const {
        rates: allRates,
        minStudents: minStudentsResponse,
        maxStudents,
        baseRate,
      } = routerPricingResponse;
      minStudents = minStudentsResponse;
      const rates = allRates.filter((rate) => {
        if (rate.duration.months === 6) hasSixMonthOption = true;
        if (months === 12) return rate.duration?.months === 12 || rate.duration?.years === 1;
        if (months === 6) return rate.duration?.months === 6;

        // We're only equipped to handle 6- and 12-month durations at this point. All others get filtered.
        return false;
      });

      if (students < minStudents) {
        error = `Group plans are only available for ${minStudents} or more students.`;
        // students = minStudents;
      } else if (students > maxStudents) {
        error = `For more than ${maxStudents} students, email sales@utheory.com for custom pricing.`;
        // students = maxStudents;
      } else if (rates.length === 0) {
        error = 'No licenses are currently available for that duration.';
      } else {
        error = '';
      }

      try {
        price = calculateGroupLicensePrice({
          students,
          months,
          rates,
          minStudents,
          maxStudents,
          baseRate,
        });
      } catch (err: any) {
        if (!error) {
          error =
            err?.message || 'No licenses available for that combination of duration and students.';
        }
      }
    }
  }

  export function getDetails(): LicenseDetails {
    return {
      type: 'group',
      quantity: students,
      months,
      starts: starts || new Date(),
      expectedSubtotal: price?.total || NaN,
    };
  }

  $: rowData = !routerPricingResponse
    ? []
    : routerPricingResponse.rates
        .filter((r) => r.students !== -1)
        .filter((r) => r.duration.months === months)
        .map((rate) => {
          const items = price?.items.find((p) => p.stripePriceId === rate.stripePriceId) || {
            quantity: 0,
            unitPrice: rate.price,
            subtotal: 0,
            tierDescription: rate.tierDescription || '',
          };
          return { ...rate, ...items, tierDescription: rate.tierDescription || '' };
        }) || [];
</script>

<div class={twMerge('sm:flex text-center w-full', clazz)}>
  <div class="w-full sm:w-1/3 md:w-1/3">
    {#if !showDates}
      <h3 class="font-semibold tracking-wide text-base md:text-lg pt-1">Pricing Calculator</h3>
    {:else}
      <div class="text-left w-fit pl-4 sm:pl-0 sm:mx-auto">
        <table class="dates-table">
          <tr>
            <th><label for="start-date">Starts:</label></th>
            <td>
              <DatePicker
                min={new Date()}
                id="start-date"
                class="border border-gray p-1"
                bind:value={starts}
                bind:isValid={dateIsValid}
              />
            </td>
          </tr>
          <tr>
            <th> Ends: </th>
            <td>
              {dateString(ends, false, { month: 'numeric', day: 'numeric', year: '2-digit' })}
            </td>
          </tr>
        </table>
      </div>
    {/if}
  </div>
  <div class="w-full sm:w-2/3 md:w-1/2 text-base text-left">
    <div class="w-fit pl-4 sm:pl-0 sm:mx-auto">
      <table class="dates-table">
        <tr>
          <th><label for="students-input">Students:</label></th>
          <td>
            <input
              id="students-input"
              class="border-gray-light border w-20 ml-4 text-base p-1 text-gray"
              maxlength="8"
              type="number"
              min={minStudents}
              bind:value={students}
              on:input={() => setStudents(students)}
            />
          </td>
        </tr>
        {#if hasSixMonthOption}
          <tr>
            <th><label for="duration-input">Duration:</label></th>
            <td>
              <select
                class="border-gray-light border w-fit ml-4 text-base px-1 pt-1 pb-1 text-gray"
                type="number"
                bind:value={months}
                on:change={() => setDuration(months)}
              >
                <option value={12} selected>12 months</option>
                <option value={6}>6 months</option>
              </select>
            </td>
          </tr>
        {/if}
      </table>
    </div>
  </div>
</div>
<div class={tight ? 'px-1 sm:px-2' : 'px-2 sm:px-8'}>
  {#if price && !error && !dateError}
    <div id="priceDiv" class="w-full">
      <!-- Students: 5,<br /> -->
      <!-- Price per student: $10.00<br /> -->
      <!-- Total (before tax): $50.00<br /><br /> -->
      <table class="pricing-table w-full mt-3 text-base">
        <thead class="text-lg xs:text-xl">
          <tr>
            <th>Stu&shy;dents</th>
            <th style="max-width: 100px;">Price Per Stu&shy;dent</th>
            <th>Quan&shy;tity</th>
            <th>Sub&shy;total</th>
          </tr>
        </thead>
        <tbody>
          {#each rowData as row}
            <tr class={row.quantity ? 'active' : 'disabled:'}>
              <td>{@html row.tierDescription.replace('tudent', 'tu&shy;dent')}</td>
              <td>${(row.unitPrice / 100).toFixed(2)}</td>
              <td>{row.quantity}</td>
              <td class={twMerge('text-green font-medium', highlightClass)}
                >{#if row.subtotal}${row.subtotal ? (row.subtotal / 100).toFixed(2) : ''}{/if}</td
              >
            </tr>
          {/each}
        </tbody>
        <tfoot>
          <tr style="text-align: right;">
            <td colspan="4" style="text-align: right;">
              Price Per Student: <span
                class="{twMerge('text-green font-medium', highlightClass)} ml-4"
                >${(price.perStudent / 100).toFixed(2)}</span
              >
            </td>
          </tr>
          <tr style="text-align: right;">
            <td colspan="4">
              Total (before tax): <span
                class="{twMerge('text-green font-medium', highlightClass)} ml-4"
                >${(price.total / 100).toFixed(2)}</span
              ></td
            >
          </tr>
        </tfoot>
      </table>
    </div>
  {/if}
  {#if error || dateError}
    {error} {dateError}
  {/if}
  {#if !routerPricingResponse && !error}
    <div>Loading license pricing...</div>
  {/if}
</div>

<style lang="postcss">
  .pricing-table {
    border-spacing: 10px;
    th {
      @apply text-left;
    }

    tbody > tr > td {
      @apply pt-2 pb-2;
    }

    tfoot {
      @apply text-lg;
    }

    td,
    th {
      padding-right: 10px;
    }
    td:not(:first-of-type),
    th:not(:first-of-type) {
      padding-left: 20px;
    }

    /* All tr's except the last have bottom borders */
    /* The footer has a top border */
    tbody > tr:not(:last-of-type) {
      @apply border-b pt-4;
      border-color: #d1d1d1;
    }

    tfoot > tr:first-of-type > td {
      padding-top: 20px;
      padding-bottom: 5px;
    }

    /* All td's except the last have right borders */
    td:not(:last-of-type),
    th:not(:last-of-type) {
      @apply border-r;
      border-color: #d1d1d1;
    }
  }

  table.dates-table {
    th,
    td {
      vertical-align: middle;
      @apply p-1 h-10;
    }
  }
</style>
