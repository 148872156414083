<script lang="ts">
  import DatePicker from '$lib/components/DatePicker.svelte';

  import type { TestingLicenseRate } from 'shared/definitions/license-definitions';
  import { dateString } from 'shared/src/utils/date.utils';
  import { loadTestingLicensePrices } from './loadLicensePrices';
  import dayjs from 'dayjs/esm/index.js';
  import { twMerge } from 'tailwind-merge';
  import { createEventDispatcher } from 'svelte';
  import { errorProvider } from '$lib/error-handling/errorProvider';
  import type { LicenseDetails } from '$lib/root/LicenseQuotingUtils';

  export let students: number = 0;
  export let showDates: boolean = false;
  export let starts: Date = new Date();
  export let rate: TestingLicenseRate | undefined = undefined;
  export let isValid: boolean = true;
  export let highlightClass = '';
  let clazz = '';
  export { clazz as class };

  const dispatch = createEventDispatcher<{ ready: null }>();

  let dateIsValid = true;

  $: ends = dayjs(starts).add(1, 'years').toDate();

  $: {
    isValid = !!rate && !!dateIsValid;
    students = !!rate ? rate.testTakers : students;
  }

  let error = '';
  let rates: TestingLicenseRate[];
  loadTestingLicensePrices()
    .then((p) => {
      rates = p;
      if (students) {
        rate =
          rates
            .sort((a, b) => {
              if (a.unlimited) return 1;
              return a.testTakers - b.testTakers;
            })
            .find(
              (potentialRate) => potentialRate.testTakers >= students || potentialRate.unlimited
            ) || rates[0]!;
      } else {
        students = rates[0]!.testTakers;
        rate = rates[0]!;
      }
      dispatch('ready');
    })
    .catch((err) => {
      error = `Sorry, there was an error loading pricing. Please refresh the page and if the problem persists,
      contact us at support@utheory.com.
      `;
      errorProvider.error(err);
      console.error(err);
    });

  export function getDetails(): LicenseDetails {
    return {
      type: 'testing',
      quantity: students,
      months: 12,
      starts: starts || new Date(),
      expectedSubtotal: rate?.total || NaN,
    };
  }
</script>

<div class={twMerge('p-4 text-gray text-xl', clazz)}>
  <p class="mb-6">
    <a
      rel="external"
      href="https://info.utheory.com/utheory-tests/"
      class="a {twMerge('text-green', highlightClass)}">Custom tests</a
    > for your classes or for placement exams.
  </p>
  {#if showDates}
    <div class="sm:flex sm:space-x-4 items-center my-6">
      <div>
        <label for="start-date">Starts:</label>
        <DatePicker
          bind:isValid={dateIsValid}
          min={new Date()}
          id="start-date"
          class="border border-gray p-1"
          bind:value={starts}
        />
      </div>
      <div>
        Ends:
        {dateString(ends, false, { month: 'numeric', day: 'numeric', year: '2-digit' })}
      </div>
    </div>
  {/if}
  {#if rates}
    <ul>
      {#each rates as potentialRate, i}
        <li>
          <input
            type="radio"
            name="testing-license"
            id="testing-price-${i}"
            value={potentialRate}
            bind:group={rate}
          />
          <label for="testing-price-${i}" class="ml-2">
            {potentialRate.unlimited ? 'Unlimited' : potentialRate.testTakers + ' Students'} (${(
              potentialRate.total / 100
            ).toFixed(2)})
          </label>
        </li>
      {/each}
    </ul>
  {:else if !error}
    Loading testing license rates...
  {:else}
    {error}
  {/if}

  <p class="mt-6">
    Each student on a license can take <span class={twMerge('text-green', highlightClass)}
      >unlimited tests</span
    >, with as many or as few attempts per test as you allow.
  </p>
</div>

<style lang="postcss">
  ul {
    /* display: table; */
    /* column-count: 2; */
    /* @apply px-2; */
  }

  @media (min-width: 570px) {
    ul {
      column-count: 2;
    }
  }

  li {
    @apply pt-2;
    /* @apply py-2; */
    /* display: inline-table; */
    /* width: 50%; */
  }
</style>
