import type {
  LicenseRouterPricingResponse,
  TestingLicenseRate,
} from 'shared/definitions/license-definitions';

let routerPricingResponse: LicenseRouterPricingResponse;

/** In milliseconds */
let groupLicenseReloadDelay = 50;

export function loadGroupLicensePrices(): Promise<LicenseRouterPricingResponse> {
  if (routerPricingResponse) return Promise.resolve(routerPricingResponse);
  return fetch(`${import.meta.env.VITE_API_URL}/pricing/licenses/include-duration`)
    .then((res) => res.json())
    .then((pricingResponse) => {
      routerPricingResponse = pricingResponse;
      return pricingResponse as Promise<LicenseRouterPricingResponse>;
    })
    .catch(() => {
      // Exponential backoff...
      const p = new Promise((resolve) => setTimeout(resolve, (groupLicenseReloadDelay *= 2)));
      return p.then(loadGroupLicensePrices);
    });
}

loadGroupLicensePrices();

let testingLicenseReloadDelay = 50;
let testingLicenseRates: TestingLicenseRate[];

export function loadTestingLicensePrices(): Promise<TestingLicenseRate[]> {
  if (testingLicenseRates) return Promise.resolve(testingLicenseRates);
  return fetch('https://utheory.com/pricing/testing-licenses')
    .then((res) => res.json())
    .then((pricingResponse) => {
      testingLicenseRates = pricingResponse;
      return pricingResponse as Promise<TestingLicenseRate[]>;
    })
    .catch(() => {
      // Exponential backoff...
      const p = new Promise((resolve) => setTimeout(resolve, (testingLicenseReloadDelay *= 2)));
      return p.then(loadTestingLicensePrices);
    });
}

loadTestingLicensePrices();
