import type {
  GroupLicensePrice,
  GroupLicenseRate,
  LicensePurchaseRequestBody,
} from '../../definitions/license-definitions';

export function calculateGroupLicensePrice({
  students,
  months = 12,
  rates,
  minStudents,
  maxStudents,
  baseRate,
  itemOverrides = [],
}: {
  students: number;
  months: number;
  rates: GroupLicenseRate[];
  minStudents: number;
  maxStudents: number;
  baseRate: number;
  itemOverrides?: LicensePurchaseRequestBody['itemOverrides'];
}): GroupLicensePrice {
  // students must be a positive integer:
  if (
    typeof students !== 'number' ||
    (students < 1 && students !== -1) ||
    ~~students !== students
  ) {
    throw new Error('Number of students must be a positive integer or -1.');
  }

  if (students < minStudents && students !== -1) {
    throw Error(`Group plans are only available for ${minStudents} or more students.`);
  }
  if (students > maxStudents) {
    throw Error('Contact us at support@utheory.com for bulk pricing.');
  }

  // Limit rates only to those that fit the duration
  rates = rates.filter((rate) => {
    const rateMonths =
      (rate.duration?.months || 0) +
      (rate.duration?.years || 0) * 12 +
      Math.floor((rate.duration?.days || 0) / 30);
    return rateMonths === months;
  });

  if (!rates.length) {
    throw Error(`No group plans are currently available for ${months}-month periods.`);
  }

  // While students is a positive integer, let's subtract:
  const items: GroupLicensePrice['items'] = [];
  let studentSubtractor = students;
  let total = 0;
  rates.forEach((rate) => {
    const isUnlimited = rate.students === -1 && students === -1;

    // Skip the unlimited plan if needed:
    if (rate.students === -1 && students !== -1) return;

    const numStudentsAtRate = isUnlimited ? 1 : Math.min(studentSubtractor, rate.students);
    const rateOverride = itemOverrides?.find((item) => item.stripePriceId === rate.stripePriceId);
    const unitPrice = rateOverride ? rateOverride.unitPrice : rate.price;
    if (numStudentsAtRate) {
      items.push({
        stripePriceId: rate.stripePriceId,
        stripeProductId: rate.stripeProductId,
        quantity: numStudentsAtRate,
        description: rate.description,
        tierDescription: rate.tierDescription,
        unitPrice,
        subtotal: numStudentsAtRate * unitPrice,
        priceOverride: !!rateOverride,
      });
    }
    total += numStudentsAtRate * unitPrice;
    studentSubtractor -= numStudentsAtRate;
    studentSubtractor = Math.max(0, studentSubtractor);
  });

  // discounts.forEach((discount) => {
  //   total -= Math.abs(discount.amount);
  //   items.push({
  //     quantity: 1,
  //     description: discount.description,
  //     unitPrice: -Math.abs(discount.amount),
  //     discount: true,
  //     subtotal: -Math.abs(discount.amount),
  //   });
  // });

  total = Math.ceil(total);
  const perStudent = Math.ceil(total / students);
  const totalBeforeDiscount = students * baseRate;
  const discountAmount = totalBeforeDiscount - total;
  const discountPercent = 100 * ((totalBeforeDiscount - total) / totalBeforeDiscount);

  return {
    students, // quantity
    perStudent, // in cents
    discountAmount, // in cents
    discountPercent, // as 55.22%
    total, // in cents
    baseRate,
    items,
  };
}
